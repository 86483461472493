import React from 'react'
import MetaTags from 'react-meta-tags'
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner } from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { useFraudRules } from './fraud-framework.hook'
import { groupBy } from 'lodash'
import { KYBStatus } from './kyb-threshold.type'

try {
    TimeAgo.addDefaultLocale(en)
} catch (e) {
    console.error(e)
}
interface PageProps {
    history: any
}

type FraudRuleProps = {
    name: string
    description: string
    actions: Record<KYBStatus, string>
    enabled: boolean
}

/**
 * Displays an individual fraud rule in a clear, understandable format which helps to visually
 * indicate if the rule triggers a block or just an alert.
 *
 * @param name - The unique name tag of the rule.
 * @param title - The title for the rule.
 * @param description - The body of the rule card.
 * @param block - Whether the rule triggers an auto-block or not.
 * @constructor
 */
const FraudRule = ({
    name,
    description,
    actions,
    enabled,
}: FraudRuleProps) => {
    const icon = enabled
        ? `mdi-play-circle text-success`
        : 'mdi-pause-circle text-danger'

    const borderColour = enabled ? `border-success` : `border-danger`

    const statusToTitle: Record<KYBStatus, string> = {
        approved: 'Approved',
        unrestrictedOther: 'Unrestricted Other',
        unrestrictedNew: 'Unrestricted New',
        restricted: 'Restricted',
        blocked: 'Blocked',
    }

    return (
        <Col md={6}>
            <Card className={`text-black-50 ${borderColour}`}>
                <CardBody>
                    <p className="card-text text-black" style={{ whiteSpace: 'pre-wrap' }}>
                        {description}
                    </p>
                    <div>
                        <table className="table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.entries(actions).map(([status, action]) => (
                                        <tr>
                                            <td>{ statusToTitle[status as KYBStatus] }</td>
                                            <td>{ action }</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <div className="d-flex align-items-center justify-content-between">
                        <small>#{name}</small>
                        <span><i className={`mdi ${icon} me-2`}></i> {enabled ? 'ENABLED' : 'DISABLED'}</span>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

const FraudRulesPage = ({ history }: PageProps) => {
    const result = useFraudRules()

    const groupByAreaRules = result.data
        ? groupBy(result.data.rules, (r => r.area))
        : []

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Fraud Rules</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs
                        title={'Fraud Prevention'}
                        breadcrumbItem="Fraud Rules"
                    />
                    <Row>
                        <Col xs={12}>
                            {
                                result.isLoading && (
                                    <div className="d-flex justify-content-center">
                                        <Spinner color="primary" />
                                    </div>
                                )
                            }
                            {
                                result.error && <p className="card-text">Could not retrieve fraud rules.</p>
                            }
                            {(Object.entries(groupByAreaRules)).map(([area, rules]) =>
                                <Card>
                                    <CardHeader>
                                        <h5 className="card-title">{area}</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            {rules.map(r => <FraudRule {...r} />)}
                                        </Row>
                                    </CardBody>
                                </Card>,
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default FraudRulesPage
