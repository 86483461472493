import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import moment from 'moment'

import {
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Container,
} from "reactstrap";

import {
  supportListMerchantActivity,
} from "../../../helpers/lopay_api_helper";

import MerchantApiDto from "../../../api-dtos/merchant/merchant.dto.interface";
import MerchantActivityItemDto from "./interfaces/merchant-activity-item-api.dto.interface";
import MerchantNotes from './components/merchant-notes/merchant-notes.component'

const RecentActivitySupportComponent = (props: {
    merchant: MerchantApiDto;
  }) => {  
    

  // List of activity records
  const [isLoadingRecentActivity, setIsLoadingRecentActivity] =
    useState<boolean>(false);
  const [activityRecords, setActivityRecords] =
    useState<{ items: MerchantActivityItemDto[] }>({
      items: [],
    });
  
  const paymentDate = (date: Date) => {
    return new Date(date);
  };

  useEffect(() => {
    if (props.merchant) {
        loadPaymentRequests(props.merchant.id);
    }
    // eslint-disable-next-line
  }, [props.merchant]);

  const getPrimaryType = (item: MerchantActivityItemDto): 
  'banned' | 'alert' | 'kyc_setting_updated' | 'note' => {
    if (item.kycNotice) {
      return item.kycNotice.action
    }
    return 'note'
  }

  const getAuthor = (item: MerchantActivityItemDto): string => {

    if (item.note?.owner) {
      return `${item.note.owner.firstName} ${item.note.owner.lastName}`
    }

    if (item.kycNotice?.supportNoteUpdatedBy) {
      return `${item.kycNotice?.supportNoteUpdatedBy.firstName} ${item.kycNotice?.supportNoteUpdatedBy.lastName}`
    }
    return ''
  }

  if (!props.merchant) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Recent Activity</title>
          </MetaTags>
          <Container fluid>
            <Alert color="danger">
              Sorry there was a problem loading the recent activity for this merchant
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  const loadPaymentRequests = async (
    merchantId: string,
  ) => {
    setIsLoadingRecentActivity(true);
    const loadedActivityRecords = await supportListMerchantActivity(
      merchantId,
    );
    setIsLoadingRecentActivity(false);
    setActivityRecords(loadedActivityRecords);
  };

  return (
    <React.Fragment>
        <MetaTags>
          <title>Recent Activity</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xs={12} lg={8}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Type</th>
                          <th>Description</th>
                          <th></th>
                        </tr>
                      </thead>
                      {(isLoadingRecentActivity && !activityRecords?.items?.length) ? (
                        <tbody><tr>
                        <th>Loading...</th></tr></tbody>
                      ) : (
                        <tbody>
                          {activityRecords &&
                            activityRecords.items.map(
                              (activityItem) => {
                                return (
                                  <tr
                                    onClick={async () => {
                                      //setSelectedPaymentRequest(paymentRequest)
                                    }}
                                    key={activityItem.id}
                                  >
                                    <td>
                                      <span className="time">
                                        {moment(
                                          paymentDate(activityItem.createdAt)
                                        ).format("MMM Do YYYY h:mm:ss a")}
                                      </span>

                                      <b>{getAuthor(activityItem)}</b>
                                    </td>
                                    <th scope="row">
                                      {getPrimaryType(activityItem)}
                                    </th>
                                    <td>
                                      {activityItem.kycNotice?.systemMessage || activityItem.note?.note}
                                    </td>
                                    <td>
                                      
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      )}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} lg={4}>
              <MerchantNotes merchantId={props.merchant.id} />
            </Col>
          </Row>
        </Container>
    </React.Fragment>
  );
};

export default RecentActivitySupportComponent;
