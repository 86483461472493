import React from 'react'
import styled from 'styled-components'
import BootstrapTable from 'react-bootstrap-table-next'
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap'
import paginationFactory from 'react-bootstrap-table2-paginator'

import { usePayments } from './support-merchant-payments.hook'
import formatPayment, {
    FormattedPayment,
} from 'src/helpers/transformers/paymentFormatter'
import PaymentFeesModal from './components/PaymentFeesModal/PaymentFeesModal'
import SupportMerchantApiDto from 'src/api-dtos/support/merchant/support-merchant-api.dto.interface'
import RefundPaymentModal from './components/RefundPaymentModal/RefundPaymentModal'

const StyledCenterContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
`

const StyledActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 150px;
`

type IComponentProps = {
    isActive: boolean
    merchant: SupportMerchantApiDto
    merchantId: string
    loadMerchant: (reloadSummary?: boolean) => Promise<void>
}

const paymentColumns = [
    {
        dataField: 'createdAt',
        text: 'Date',
    },
    {
        dataField: 'total',
        text: 'Amount',
    },
    {
        dataField: 'card',
        text: 'Card',
    },
    {
        dataField: 'fee',
        text: 'Fees',
    },
    {
        dataField: 'tip',
        text: 'Tip',
    },
    {
        dataField: 'discount',
        text: 'Fee Discount',
    },
    {
        dataField: 'net',
        text: 'Net',
    },
    {
        dataField: 'schedule',
        text: 'Schedule',
    },
    {
        dataField: 'description',
        text: 'Description',
    },
]

const SupportMerchantPayments: React.FC<IComponentProps> = ({
    isActive,
    merchantId,
    merchant,
    loadMerchant,
}) => {
    const [expanded, setExpanded] = React.useState<string[]>([])
    const [maxPageLoaded, setMaxPageLoaded] = React.useState(1)
    const [tablePage, setTablePage] = React.useState(1)
    const [tableSizePerPage, setTableSizePerPage] = React.useState(10)

    const [isPaymentFeesModalOpen, setIsPaymentFeesModalOpen] =
        React.useState(false)

    const [isRefundModalOpen, setIsRefundModalOpen] = React.useState(false)

    const [selectedPayment, setSelectedPayment] = React.useState<
        FormattedPayment | undefined
    >(undefined)

    const paymentResults = usePayments({
        isActive,
        merchantId,
        pageSize: tableSizePerPage,
    })

    const payments = React.useMemo(
        () =>
            (paymentResults.data?.pages ?? []).flatMap((page) =>
                (page.transactions ?? []).map(formatPayment)
            ),
        [paymentResults.data]
    )

    const data = React.useMemo(() => {
        const currentIndex = (tablePage - 1) * tableSizePerPage

        return payments.slice(currentIndex, currentIndex + tableSizePerPage)
    }, [payments, tableSizePerPage, tablePage])

    const togglePaymentFeesModal = (payment?: FormattedPayment) => {
        if (!isPaymentFeesModalOpen && payment) {
            setSelectedPayment(payment)
        }

        setIsPaymentFeesModalOpen((prevState) => !prevState)
    }

    const toggleRefundModel = (payment?: FormattedPayment) => {
        if (!isRefundModalOpen && payment) {
            setSelectedPayment(payment)
        }

        setIsRefundModalOpen((prevState) => !prevState)
    }

    const canRefund = (payment: FormattedPayment) => {
        return (
            !['bank_transfer', 'cash'].includes(payment.card) &&
            !payment.total.includes('-')
        )
    }

    if (paymentResults.isLoading || paymentResults.isFetchingNextPage) {
        return (
            <StyledCenterContainer>
                <Spinner className="ms-2" color="primary" />
            </StyledCenterContainer>
        )
    }

    if (paymentResults.isError) {
        return (
            <StyledCenterContainer>
                Something went wrong whilst loading payments. Please try again.
            </StyledCenterContainer>
        )
    }

    if (payments.length === 0) {
        return (
            <React.Fragment>
                <div
                    className="table-responsive"
                    style={{
                        paddingBottom: 550,
                        marginBottom: -550,
                        msOverflowStyle: 'none',
                    }}
                >
                    <StyledCenterContainer>
                        The merchant has no payments.
                    </StyledCenterContainer>
                </div>
            </React.Fragment>
        )
    }

    return (
        <Row>
            <Col className="col-12">
                <Card>
                    <CardBody>
                        <BootstrapTable
                            remote
                            hover
                            keyField="id"
                            bordered={false}
                            striped={false}
                            data={data}
                            columns={paymentColumns}
                            defaultSorted={[
                                {
                                    dataField: 'Date',
                                    order: 'desc',
                                },
                            ]}
                            classes={'table align-middle table-nowrap'}
                            headerWrapperClasses={'thead-light'}
                            pagination={paginationFactory({
                                withFirstAndLast: false,
                                sizePerPage: tableSizePerPage,
                                page: tablePage,
                                totalSize:
                                    paymentResults.data?.pages[0]?.count ?? 0,
                            })}
                            expandRow={{
                                renderer: (row) => {
                                    return (
                                        <StyledActionContainer>
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    togglePaymentFeesModal(row)
                                                }}
                                            >
                                                View details
                                            </button>
                                            {canRefund(row) && (
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        toggleRefundModel(row)
                                                    }}
                                                >
                                                    Refund
                                                </button>
                                            )}
                                        </StyledActionContainer>
                                    )
                                },
                                expanded,
                                onExpand: (row, isExpanded) => {
                                    if (isExpanded) {
                                        setExpanded((prev) => [...prev, row.id])
                                    } else {
                                        setExpanded((prev) =>
                                            prev.filter(
                                                (paymentId) =>
                                                    paymentId !== row.id
                                            )
                                        )
                                    }
                                },
                            }}
                            onTableChange={async (_, { page, sizePerPage }) => {
                                if (
                                    tablePage !== page ||
                                    tableSizePerPage !== sizePerPage
                                ) {
                                    setExpanded([])
                                }

                                if (tableSizePerPage !== sizePerPage) {
                                    setTablePage(1)
                                    setTableSizePerPage(sizePerPage)
                                    setMaxPageLoaded(1)

                                    return
                                }

                                if (page > maxPageLoaded) {
                                    await paymentResults.fetchNextPage()
                                    setMaxPageLoaded(page)
                                }

                                setTablePage(page)
                                setTableSizePerPage(sizePerPage)
                            }}
                        />
                        <PaymentFeesModal
                            isOpen={isPaymentFeesModalOpen}
                            merchant={merchant}
                            payment={selectedPayment}
                            toggle={togglePaymentFeesModal}
                        />
                        <RefundPaymentModal
                            isOpen={isRefundModalOpen}
                            merchant={merchant}
                            payment={selectedPayment}
                            toggle={toggleRefundModel}
                            loadMerchant={loadMerchant}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default SupportMerchantPayments
