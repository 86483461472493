import { useQuery } from 'react-query'
import { getFraudRules, getKybThresholds } from './fraud-framework.api'

export function useFraudRules() {
    return useQuery({
        queryKey: ['fraudRules'],
        queryFn: () => getFraudRules()
    })
}

export function useKybThresholds() {
    return useQuery({
        queryKey: ['kybThresholds'],
        queryFn: () => getKybThresholds()
    })
}