import { FraudRulesResponseDto } from './fraud-framework.type'
import { GetRequest, PostRequest } from '../../helpers/lopay_api_helper'
import { KYBStatus, KYBThreshold } from './kyb-threshold.type'

export function getFraudRules(): Promise<FraudRulesResponseDto> {
    return GetRequest(`support/fraud-rules`)
}

export function getKybThresholds(): Promise<KYBThreshold[]> {
    return GetRequest(`support/kyb-thresholds`)
}

export function updateKybThresholds(kybStatus: KYBStatus, data: Partial<KYBThreshold>): Promise<void> {
    return PostRequest(`support/kyb-thresholds/${kybStatus}`, data)
}